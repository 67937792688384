/*
All the Napalm specific style should be added here
*/
@import "lbd/variables";
@import "lbd/mixins";
@import "lbd/typography";

.text-small {
    font-size: .85em;
}

.alert.napalm-alert-primary {
    color: #444444;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 2px solid $primary-color;
}

.alert.napalm-alert-danger {
    color: $danger-states-color;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 2px solid $danger-color;
}

.alert.napalm-alert-warning {
    color: #444444;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 2px solid $warning-color;
}

.alert.napalm-alert-success {
    color: #444444;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 2px solid $success-color;
}

.label {
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.bg-light-gray {
    background-color: $medium-gray;
}

.swal2-icon .swal2-icon-content {
    font-size: inherit !important;
}

.el-select .el-input:hover .el-input__icon, .el-select .el-input:hover input {
    color: #99999e !important;
}

.el-input:hover input::placeholder {
    color: #99999e !important;
}

.el-form-item {
    margin-bottom: 0 !important;
}

.el-form-item__label {
    line-height: 19px !important;
}

.el-form-item.is-required > .el-form-item__label:before {
    content: '' !important;
    display: none !important;
}

.el-form-item__error {
    color: #fb404b !important;
    font-size: 90% !important;
    margin-top: 0.25rem !important;
    position: unset !important;
}

ul.el-timeline {
    padding-inline-start: 0 !important;
}

.timeline-note {

    :before {
        content: "";
        position: absolute;
        left: -6px;
        top: 0;
        height: 100%;
        border-left: 3px solid;
        border-radius: 3px;
        border-color: $primary-color;
    }
}

.el-form {
    .el-form-item {
        margin-top: 1.25rem;
    }
}

.el-input__wrapper {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
}
